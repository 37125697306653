<template>
  <div v-if="entryForm && entry">

    <entry-form actionText="Update" @submitted="formSubmitted"></entry-form>

  </div>
</template>
<script>
import EntryForm from 'components/super/EntryForm.vue';
import {ENV_URL, MAIN_URL, STRIPE_PUBLISHABLE} from '../../environment.js';
import axios from 'axios';

export default {
  name: 'directory-view',

  components: {
    EntryForm,
  },
  data () {
    console.log('current page', this.$route)
    return {
    }

  },
  created() {


    this.$store.dispatch('LOADING', true);

    

    const uri = ENV_URL + this.$route.path;
    this.$store.dispatch('SUPER_ENTRIES_ENTRY_UPDATE', {uri: uri}).then(() => {

      this.$store.dispatch('LOADING', false);

    })

  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    entryForm() {
      return this.$store.state.entryForm;
    },
    entry() {
      return this.$store.state.entry;
    },
  },

  mounted() {

  },

  methods: {
    formSubmitted(e) {
    let categories = this.entry.categories['hcategory'];
    console.dir(this.entry.categories['hcategory'])
      this.$store.dispatch('LOADING', true);

      var laravelAuthToken = localStorage.getItem('laravelAuthToken');

      let authHeaders = {
        headers: {"Authorization" : `Bearer ${laravelAuthToken}`}
      };

      const item_data = {
        entry_form_fields: this.entryForm.fields,
        categories: [this.entry.categories['hcategory']]
      }
      console.dir(item_data)
      // /super/contests/{contest}/entries/{id}/update
      let uri = ENV_URL + this.$route.path + '/update';
      axios.patch(uri, item_data, authHeaders).then((response) => {
        this.$store.dispatch('LOADING', false);
        alert('Your item was updated!');
      })
      .catch((error) => {
        console.log('app error: ', error.message);
        this.$store.dispatch('LOADING', false);
        alert(error.message);
      })
    },

  },

}
</script>

<style lang="scss">

</style>
